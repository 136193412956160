import React from 'react'
import s from './GridCell.module.scss';
import cm from 'classnames'
function GridCell({ onClick, activeCell, status }) {
    const arr = Array.from(Array(20).keys())

    return (
        <div className={cm(s.GridCell, {
            [s.Green]: status === 1,
            [s.Orange]: status === 7,
            [s.Red]: status === 0
        })}>
            {arr.map((item, index) => {
                return (
                    <div key={index} onClick={() => onClick(index)} className={cm(s.GridItem, { [s.Active]: activeCell.includes(index) })}>
                        {index + 1}
                    </div>
                )
            })}
        </div>
    )
}

GridCell.defaultProps = {
    onClick: () => { }
}

export default GridCell;