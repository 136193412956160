// # Core
import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

// # Components
import Icon from '../Icon';
import omnic from './innovation.png'


class Header extends PureComponent {
  render() {
    return (
      <header className="header">
        <div className="header__logo">
          <Icon type="logo" />
        </div>
        <div className="header__support">
          <img src={omnic} alt="" />
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
