// # Development/Production switch mode
const isProd = true;

// # Language variables
export const LANG = 'ru';

// # API URLs
export const API_URL = isProd
	? 'https://burger.omnic.solutions'
	: 'http://192.168.200.226:8080'; // last prod: https://test.omnic.solutions/

export const MODULE_UID = isProd
	? '2c28920321d94b389c26955a21266064'
	: '21e4f109d25541dfa82d498f2ffee9ce'; // last prod: 'bf5edbf6c4424d7fb58ea9a75026688a'
export const TOKEN = isProd
	? '93fec3798635a020ff44a3ad59a68fe689d09083a3d206889180a26300108174'
	: '93fec3798635a020ff44a3ad59a68fe689d09083a3d206889180a26300108174'; // last prod: 1819281d011d44ee533228bcc9084c52a73a8870
