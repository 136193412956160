// # Core
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { string, func } from 'prop-types';


class Button extends PureComponent {
  render() {
    const {
      children,
      className,
      onClick,
      to,
    } = this.props;

    const defaultAttributes = {
      className,
      draggable: false,
      onClick,
    }

    return (to)
      ? (
        <Link to={to} {...defaultAttributes}>
          <span className="button__label">{children}</span>
        </Link>
      ) : (
        <div {...defaultAttributes}>
          <span className="button__label">{children}</span>
        </div>
      );
  }
}


Button.propTypes = {
  children: string.isRequired,
  className: string.isRequired,
  onClick: func,
  to: string,
};

Button.defaultProps = {
  onClick: () => {},
  to: '',
};


export default Button;
