import React, { Component } from 'react'
import s from './OpenCell.module.scss'
import GridCell from '../../components/GridCell'
import { withRouter } from 'react-router-dom'
import Button from '../../components/Button';
import axios from 'axios';
import { API_URL, MODULE_UID, TOKEN } from '../../globalVariables'
import { filter, map } from 'lodash-es'
import cm from 'classnames'

class OpenCell extends Component {
    constructor(props) {
        super(props);
        this.setState = {
            openCells: []
        }
    }

    fetchOpenCells = async () => {
        const { history, match: { params } } = this.props;
        return await axios.get(`${API_URL}/api/1.4.0/module/${MODULE_UID}/cells/statuses/`,
            {
                headers: {
                    Token: `${TOKEN}`,
                }
            })
            .then((res) => {
                const { status_code: statusCode, data: cells } = res.data;

                if (statusCode === 200) {
                    const openCells = filter(cells, (cell) => {
                        if (cell.cell_status === 0)
                            return cell;
                    })
                    if (openCells.length === 0) {
                        history.push(`/get-order/${params.code}`)
                    } else {
                        this.setState({ openCells })
                    }
                }

            })
            .catch(error => console.log('fetchOpenCells functional error: ', error));
    }
    fetchGetOrder = async () => {
        const { match: { params }, history } = this.props;
        const { orderData: { stack, cell } } = this.state
        if (params.code)
            return await axios.post(`${API_URL}/api/1.4.0/order/vending/receipt/`, {
                "m_uid": MODULE_UID,
                "recipient_code": params.code
            }, {
                headers: {
                    Token: `${TOKEN}`,
                }
            })
                .then((res) => {
                    const { status_code: statusCode, data } = res.data;

                    if (statusCode === 200) {
                        history.push(`/get-order/${stack}/${cell}`)
                        console.log('fetchGetOrder', data)
                    }
                })
                .catch(error => console.log('fetchGetOrder functional error: ', error));
    }
    render() {
        const { openCells } = this.state;
        //status
        const activeCells = map(openCells, ({ stack, cell }) => {
            return stack === 0 ? +cell : +cell + 10
        })
        return (
            <div className={s.Root}>
              <p className={s.Title}>There are open lockers!</p>
              <p className={s.Subtitle}>
                Please close the specified locker <br /> to receive your order.
              </p>
              <div className={s.Grid}>
                <GridCell activeCell={activeCells} status={7} />
              </div>
              <Button className={cm(`button button--red`, s.Button)}>LOCKER IS BROKEN</Button>
            </div>
          );
    }
}
export default withRouter(OpenCell);