import React, { Component } from 'react'
import s from './Main.module.scss'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import Button from '../../components/Button'
import { API_URL, MODULE_UID } from '../../globalVariables'

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orderData: {},
            orderNotFound: false
        }
    }
    componentDidMount() {
        this.fetchGetDetailsOrderByCode()
    }
    fetchOpenCells = async () => {
        const { history, match: { params } } = this.props;
        const { orderData: { stack, cell } } = this.state

        return await axios.get(`${API_URL}/api/1.4.0/order/vending/module/${MODULE_UID}/cells/statuses/`)
            .then((res) => {
                // const { status_code: statusCode, data: cells } = res.data;

                history.push(`/get-order/${params.code}/${stack}/${cell}`)


            })
            .catch(error => console.log('Refresh functional error: ', error));
    }

    fetchGetDetailsOrderByCode = async () => {
        const { match: { params } } = this.props;
        if (Number(params.code))
            return await axios.get(
                `${API_URL}/api/1.4.0/order/vending/check/${params.code}/`
            )
                .then((res) => {
                    const { status_code: statusCode, data } = res.data;

                    if (statusCode === 200) {
                        this.setState({
                            orderData: data, orderNotFound: false
                        })
                    } else {
                        this.setState({ orderNotFound: true })
                    }
                })
                .catch(error => console.log('Refresh functional error: ', error));
    }


    render() {
        const { orderData, orderNotFound } = this.state
        return (
            <div className={s.Root}>
              {orderNotFound || Object.keys(orderData).length === 0 ? (
                <div className={s.NotFound}>
                  Your order was not found
                </div>
              ) : (
                <>
                  <p className={s.Title}>
                    Your order <br />
                    {`No.${orderData.order_number} is ready!`}
                  </p>
                  <div className={s.Image}>
                    <img src={`${API_URL}${orderData.product_image}`} alt="" />
                  </div>
                  <Button onClick={this.fetchOpenCells} className={`button button--green`}>
                    CONTINUE
                  </Button>
                </>
              )}
            </div>
          );
    }
}
export default withRouter(Main);