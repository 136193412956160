import React from 'react'
import Icon from '../../components/Icon'
import s from './BonAppetit.module.scss'

export default function BonAppetit() {
    return (
        <div className={s.Root}>
            <Icon type="bon-appetit" />
        </div>
    )
}
