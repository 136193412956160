import React, { Component } from 'react';
import s from './GetOrder.module.scss';
import GridCell from '../../components/GridCell';
import { withRouter } from 'react-router-dom';
import Button from '../../components/Button';
import axios from 'axios';
import { API_URL, MODULE_UID } from '../../globalVariables';
import cm from 'classnames';

class GetOrder extends Component {
	constructor(props) {
		super(props);
		this.setState = {
			stack: props.match.params.stack,
			cell: props.match.params.cell
		};
	}

	fetchGetDetailsOrderByCode = async () => {
		const {
			match: {
				params: { code }
			}
		} = this.props;
		if (code)
			return await axios
				.get(`${API_URL}/api/1.4.0/order/vending/check/${code}/`)
				.then(res => {
					const { status_code: statusCode, data } = res.data;

					if (statusCode === 200) {
						this.setState({
							orderData: data,
							orderNotFound: false
						});
					} else {
						this.setState({ orderNotFound: true });
					}
				})
				.catch(error =>
					console.log('Refresh functional error: ', error)
				);
	};
	fetchGetOrder = async () => {
		const {
			match: {
				params: { code }
			},
			history
		} = this.props;
		if (Number(code))
			return await axios
				.post(`${API_URL}/api/1.4.0/order/vending/receipt/`, {
					m_uid: MODULE_UID,
					recipient_code: code
				})
				.then(res => {
					const { data } = res.data;
					console.log('fetchGetOrder', data);
					history.push('/success/bon-appetit');
				})
				.catch(error =>
					console.log('Refresh functional error: ', error)
				);
	};
	render() {
		const {
			match: {
				params: { stack, cell }
			},
			history
		} = this.props;
		//status
		const activeCell = +stack === 0 ? +cell : +cell + 10;
		return (
			<div className={s.Root}>
			  <p className={s.Title}>
				Collect Your Order
				<br />from the Specified Locker
			  </p>
			  <p className={s.Subtitle}>
				Please remember to close the locker after collecting your order.
			  </p>
			  <div className={s.Grid}>
				<GridCell activeCell={[activeCell]} status={1} />
			  </div>
			  <Button
				className={cm(`button button--green`, s.Button)}
				onClick={this.fetchGetOrder}
			  >
				OPEN LOCKER
			  </Button>
			  <p className={s.NavButton} onClick={() => history.goBack()}>
				BACK
			  </p>
			</div>
		  );
	}
}
export default withRouter(GetOrder);
