import React, { Fragment } from 'react';
import { Route } from 'react-router-dom';
import Header from './components/Header';
import pages from './pages';

function App() {
	return (
		<Fragment>
			<Header />

			<div className="l-container">
				<Route exact path="/open-cell" component={pages.OpenCell} />
				<Route
					exact
					path="/get-order/:code/:stack/:cell"
					component={pages.GetOrder}
				/>
				<Route
					exact
					path="/success/bon-appetit"
					component={pages.BonAppetit}
				/>
				<Route exact path="/:code" component={pages.Main} />
			</div>
		</Fragment>
	);
}

export default App;
