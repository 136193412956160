// # Components
import Main from './main';
import OpenCell from './open-cell';
import GetOrder from './get-order';
import BonAppetit from './bon-appetit';

const pages = {
	Main,
	GetOrder,
	OpenCell,
	BonAppetit
};

export default pages;
